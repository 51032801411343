<template>
    <div>
        <div v-if="successMessage" class="alert alert-success" style="color: rgb(5, 20, 48)">
            {{ successMessage }}
        </div>
        <b-card>
            <b-row>
                <div class="col-12 mt-16">
                    <b-form @submit.prevent="addUser" v-if="show">
                        <div style="
                background-color: rgb(97, 116, 152);
                height: 32px;
                border-radius: 4px;
              ">
                            <h4 style="
                  color: rgb(223, 227, 238);
                  margin-left: 5px;
                  font-weight: bold;
                ">
                                Product Information
                            </h4>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Title:" label-for="name">
                                    <b-form-input id="name" placeholder="Enter name"
                                        v-model="name" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Amount:" label-for="price">
                                    <b-form-input id="price" placeholder="Enter price"
                                        v-model="price" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Description:" label-for="description">
                                    <b-form-input id="description" placeholder="Enter description"
                                        v-model="description" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                        
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Pictures:" label-for="image">
                                <!-- Display existing images -->
                                <div v-if="imageArray.length" style="margin-bottom: 15px;">
                                    <div v-for="(img, index) in imageArray" :key="index" style="display:inline-block; margin-right:10px;">
                                    <img :src="'https://wossdb.wossautoid.com/' + img" alt="Picture" style="max-width: 100px; max-height: 100px" />
                                    </div>
                                </div>
                                <!-- Multiple file input -->
                                <input type="file" accept="image/*" id="image" multiple @change="onMultipleImagesChange" />
                                <!-- Preview new images -->
                                <div v-if="newImagePreviews.length" style="margin-top: 10px;">
                                    <div v-for="(img, index) in newImagePreviews" :key="index" style="display:inline-block; margin-right:10px;">
                                    <img :src="img" alt="New Picture" style="max-width: 100px; max-height: 100px" />
                                    </div>
                                </div>
                                </b-form-group>
                            </div>
                        </div>
                        <b-button type="submit" variant="primary" class="mb-8 mr-8" :disabled="isLoading">
                            <span v-if="!isLoading">Submit</span>
                            <b-spinner v-else class="mb-8 mr-8" variant="primary" small></b-spinner>
                        </b-button>
                    </b-form>
                </div>
                <div v-if="codeActive" class="col-12 mt-24 hljs-container" :class="{ active: codeActiveClass }">
                    <pre v-highlightjs>
            <code class="hljs html">
              {{ codeText }}
            </code>
          </pre>
                </div>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormSelect,
    BFormInput,
    BSpinner,
} from "bootstrap-vue";
// import axios from "axios";
import axios from "../../../axios";
import { BToast } from "bootstrap-vue";
import code from "@/view/components/data-entry/form/code";

export default {
    data() {
        return {
            show: true,
            codeText: code.introduction,
            codeActive: false,
            codeActiveClass: false,
            selectedOption: "",
            inputField1: "",
            inputField2: "",
            inputField3: "",
            showModal: false,
            isLoading: false,
            name: "",
            price:"",
            description: "",
            imageArray: [],  
            newImages: [],   
            newImagePreviews: [],
          
        };
    },
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormSelect,
        BFormInput,
        BToast, 
        BSpinner,
    },

    created() {
        axios
        const userId = this.$route.params.id;
        axios
            .get(`ProductShow/${userId}`)
            .then((response) => {

                this.editedUser = response.data.data;
                this.name = this.editedUser.name;
                this.price = this.editedUser.price;
                this.imageArray = this.editedUser.image;
                this.description = this.editedUser.description;           
            })
            .catch((error) => {
                console.error("Error fetching user data:", error);
            });
    },

    methods: {
        showMsgBoxOne() {
            debugger;
        },
        onSubmit(event) {
            event.preventDefault();
            alert(JSON.stringify(this.form));
        },
      
        addUser() {
            this.isLoading = true;
            const formData = new FormData();
            formData.append("name", this.name);
            formData.append("price", this.price);
            formData.append("description", this.description); 
             // Append each new image to the form data
             this.newImages.forEach((image, index) => {
                formData.append(`image[${index}]`, image);
            });
    
            axios
                .post(`ProductUpdate/${this.editedUser.id}`, formData)
                .then((response) => {
                    console.log(response.data);
                    this.$bvToast.toast("Product Update successfully!", {
                        title: "Success",
                        variant: "success",
                        solid: true,
                        appendToast: true,
                        toaster: "b-toaster-top-right",
                        autoHideDelay: 5000,
                        variant: "primary", 
                    });
                    this.isLoading = false;
                    this.$router.push({ name: 'ListProduct' });
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    console.log(error.response.data);
                    this.isLoading = false;
                });
        },
        codeClick() {
            this.codeActive = !this.codeActive;
        },

        saveOwnCar() {
            this.showModal = false;
        },
        // Method to handle multiple image selection
        onMultipleImagesChange(event) {
            const files = event.target.files;
            this.newImages = Array.from(files); 

            // Reset the image previews
            this.newImagePreviews = [];

            // Generate image previews
            Array.from(files).forEach((file) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.newImagePreviews.push(e.target.result);
                };
                reader.readAsDataURL(file); 
            });
        },
    },
};
</script>